code {
  color: #000;
}
hr {
  height: 0.5px;
}
.hljs {
  background: transparent;
  padding: 0;
  font-size: 12.25px;
}