.inlineCode {
  margin-bottom: 0;
}

input {
  padding:0;
  border:0;
  display: inline-block;
  background-color: rgb(240, 240, 240);
}
input:focus {outline:none!important;}

.page_link:hover {
  background-color: rgba(211, 211, 211, 0.082);
}

.page_link.active {
  color: black;
  background-color: rgba(211, 211, 211, 0.205);
}

select {
}

select:focus-visible {
  outline: none;
}